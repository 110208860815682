import axios from 'axios';
import AlertServie from './errors.service';
import router from '@/router';

const ErrorHandlerService = {
  init() {
    var self = this;
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
          localStorage.removeItem('loggedIn');
          localStorage.removeItem('access_token');
          localStorage.removeItem('user');
          localStorage.removeItem('permissions');
          localStorage.removeItem('role');
          router.push('/login');
          AlertServie.errorMessage('Session Expired, please login again.');
        } else if (status == 422) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            AlertServie.errorMessage(error.response.data.errors[key][0]);
          }
        } else if (status == 500 || status == 429) {
          router.push({ name: '500' });
        } else if (status == 403) {
          router.push({ name: '404' });
        } else if (status == 404) {
          router.push({ name: '404' });
        } else if (status == 400) {
          AlertServie.errorMessage(error.response.data.message);
        } else if (status === 402) {
          router.push({ name: 'expirePlan' });
        } else if (status === 423) {
          router.push({ name: 'Inactive' });
        }
        return Promise.reject(error);
      }
    );
  }
};

export default ErrorHandlerService;
