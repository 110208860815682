import usePositions from '../../composable/position.js';
import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

const { getPositions } = usePositions();

export const state = {
  positions: [],
  position: []
};

export const mutations = {
  SET_POSITIONS(state, newValue) {
    state.positions = newValue;
  },
  FILTER_POSITIONS(state, newValue) {
    state.positions = state.position;
  },
  ADD_POSITION(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.positions.unshift(newData);
  },
  DELETE_POSITION(state, id) {
    const index = state.positions.findIndex((item) => item.id === id);
    state.positions.splice(index, 1);
  },
  UPDATE_POSITION(state, { id, data }) {
    const index = state.positions.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.positions[index] = newData;
  }
};

export const getters = {
  getpositions(state) {
    return state.positions;
  }
};

export const actions = {
  getPositions({ commit, dispatch, getters }, query) {
    return getPositions(query).then((resp) => {
      const response = resp.data.data;
      commit('SET_POSITIONS', response);
    });
  },

  async addPosition({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/position', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.data;
        commit('ADD_POSITION', data);
      }
    } catch (er) {}
  },

  async deletePosition({ commit }, id) {
    try {
      const response = await ApiService.delete('/position/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('DELETE_POSITION', id);
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updatePosition({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('position/update/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.data;
        commit('UPDATE_POSITION', { id, data });

        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async searchAndFilterpositions({ commit, state }, searchQuery) {
    const normalizedQuery = searchQuery.toLowerCase().trim();
    if (!normalizedQuery) {
      // If the query is empty, set filtered positions to all positions
      commit('SET_POSITIONS', state.positions);
    } else {
      const filteredpositions = state.positions.map((position) => position.fullname.toLowerCase().includes(normalizedQuery));
      commit('SET_POSITIONS', filteredpositions);
    }
  }
};
