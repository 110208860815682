<template>
  <router-view></router-view>
  <plan-pricing />
</template>

<script>
import { authComputed, authMethods } from './state/helpers';
import PlanPricing from '@/components/plan-pricing.vue';

export default {
  name: 'App',
  components: { PlanPricing },
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    ...authMethods,
    ...authComputed,
    fetchData() {
      this.$store.dispatch('groups/fetchGroups');
      this.$store.dispatch('user/getAllUser');
    }
  },

  mounted() {
    // get theme from local storage if available and set it to the document element
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.setAttribute('data-bs-theme', savedTheme);
    }
  },

  created() {
    if (localStorage.getItem('access_token')) {
      this.getUsersData();
      this.fetchData();
    }
  }
};
</script>
