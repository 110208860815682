import { ref } from 'vue';
import ApiService from '../services/api.service';
import AlertService from '../services/errors.service';

export default function useTaskStatus() {
  const storeProjectStatus = ref(false);
  const editProjectStatus = ref(false);
  const isTaskLisLoading = ref(false);
  const deletedStatusId = ref(null);
  const taskStatuses = ref([]);

  const getTaskStatus = async (id, isAllTask = true, isNeedLoading = false) => {
    try {
      if (isNeedLoading) isTaskLisLoading.value = true;
      let path = '/project/' + id + '/task_tatus';
      if (!isAllTask)
        path +=
          '?' +
          new URLSearchParams({
            assignedTasksOnly: true
          });
      const response = await ApiService.get(path).finally(() => {
        isTaskLisLoading.value = false;
      });

      taskStatuses.value = response.data.data;
    } catch (error) {}
  };

  const changeStatusOfTask = async (taskID, statusID, id, isAllTask) => {
    try {
      const response = await ApiService.put('/task/' + taskID + '/status-update', {
        task_status_id: statusID
      });
      AlertService.successMessage(response?.data?.message);
    } catch (error) {
    } finally {
      await getTaskStatus(id, !!isAllTask);
    }
  };

  const changeOrderStatus = async (id, isAllTask) => {
    try {
      const ids = taskStatuses.value.map((item) => item?.id);
      const response = await ApiService.get('/task_status/' + id + '/sort-update/' + ids);
      AlertService.successMessage(response.data.message);
    } catch (error) {
      await getTaskStatus(id, !!isAllTask);
    }
  };

  const storeStatus = async (data) => {
    try {
      const response = await ApiService.post('/task_status', data);
      if (response.status === 201) {
        storeProjectStatus.value = true;
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };
  const addSingleTask = async (data, id, isAllTask) => {
    try {
      const response = await ApiService.post('/task', data);
      if (response.status === 201) {
        await getTaskStatus(id, !!isAllTask);
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const UpdateSingleTask = async (task_id, project_id, data, isAllTask) => {
    try {
      const response = await ApiService.post('/task/' + task_id, data);
      if (response.status === 200) {
        await getTaskStatus(project_id, !!isAllTask);
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const deleteSingleTask = async (task_id, _project_id, isAllTask) => {
    try {
      const response = await ApiService.delete('/task/' + task_id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        await getTaskStatus(_project_id, !!isAllTask);
      }
    } catch (error) {}
  };

  const showSingleTaskDetail = async (id) => {
    try {
      return await ApiService.get('/task/' + id);
    } catch (error) {
      return [];
    }
  };

  const updateStatus = async (id, data) => {
    try {
      const response = await ApiService.put('/task_status/' + id, data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        editProjectStatus.value = true;
      }
    } catch (error) {}
  };

  const destroyStatus = async (id) => {
    try {
      const response = await ApiService.delete('/task_status/' + id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        deletedStatusId.value = id;
      }
    } catch (error) {}
  };

  const addComment = async (data) => {
    try {
      return await ApiService.post('/comments', data);
    } catch (error) {
      return [];
    }
  };

  return {
    storeProjectStatus,
    editProjectStatus,
    deletedStatusId,
    taskStatuses,

    changeStatusOfTask,
    getTaskStatus,
    changeOrderStatus,
    storeStatus,
    updateStatus,
    destroyStatus,
    isTaskLisLoading,
    addSingleTask,
    showSingleTaskDetail,
    addComment,
    deleteSingleTask,
    UpdateSingleTask
  };
}
