import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

export const state = {
  forms: [],
  isLoading: false
};

export const mutations = {
  SET_FORM(state, newValue) {
    state.forms = newValue;
  },

  SET_LOADING(state, newValue) {
    state.isLoading = newValue;
  },

  ADD_FORM(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.forms.unshift(newData);
  }
};

export const getters = {
  getForms(state) {
    return state.forms;
  },
  getLoading(state) {
    return state.isLoading;
  }
};

export const actions = {
  async getForms({ commit, dispatch, getters }, query) {
    try {
      commit('SET_LOADING', true);

      const response = await ApiService.get('/vas/vas-request?' + new URLSearchParams(query));

      if (response.status === 200) {
        const data = response.data;
        commit('SET_FORM', data);
      }
    } catch (error) {
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addForm({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/vas/vas-request', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.purchaseRequest;
        commit('ADD_FORM', data);
      }
    } catch (error) {}
  }
};
