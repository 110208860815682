import useProjects from '../../composable/project';
import AlertService from '../../services/errors.service';
import ApiService from '../../services/api.service';

const { getProjects } = useProjects();

export const state = {
  projects: [],
  project: [],
  groups: [],
  isProjectListLoading: false
};

export const mutations = {
  SET_LOADING(state, newValue) {
    state.isProjectListLoading = newValue;
  },
  SET_PROJECTS(state, newValue) {
    state.projects = newValue;
  },
  SET_GROUPS(state, newValue) {
    state.groups = newValue;
  },
  deleteItem(state, id) {
    const index = state.projects.data.findIndex((item) => item.id === id);

    state.projects.data.splice(index, 1);
  }
};

export const getters = {
  getProjects(state) {
    return state.projects;
  },
  getGroups(state) {
    return state.groups;
  },
  isLoading(state) {
    return state.isProjectListLoading;
  }
};

export const actions = {
  getProjectsData({ commit, dispatch, getters }, query) {
    commit('SET_LOADING', true);
    return getProjects(query)
      .then((resp) => {
        const response = resp.data;
        commit('SET_PROJECTS', response);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  async getGroups({ commit, dispatch, getters }, id) {
    const response = await ApiService.get('/groups/' + id);
    if (response.status === 200) {
      const data = response.data.data;
      commit('SET_GROUPS', data);
    }
  },

  async deleteProject({ commit }, id) {
    try {
      const response = await ApiService.delete('/project/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('deleteItem', id);
      }
    } catch (error) {
      AlertService.errorMessage(error);
    }
  }
};
