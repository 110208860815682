import useAuth from '../../composable/auth.js';
import useUsers from '../../composable/users.js';

import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

const { submitLogin } = useAuth();
const { getUserData } = useUsers();

export const state = {
  access_token: '',
  currentUser: [],
  UsersPermission: [],
  UserProjectPermissions: [],
  user_id: 0,
  role: ''
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    state.role = newValue.role;
  },
  SET_CURRENT_USER_PERMISSION(state, newValue) {
    state.UsersPermission = newValue;
  },
  SET_CURRENT_USER_PROJECT_PERMISSION(state, newValue) {
    state.UserProjectPermissions = newValue;
  },
  SET_USER_TOKEN(state, newValue) {
    state.access_token = newValue;
  },
  SET_CURRENT_USER_ID(state, newValue) {
    state.user_id = newValue;
  }
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return state.access_token != '';
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  getUsersPermission(state) {
    return state.UsersPermission;
  },
  hasPermission: (state) => (requiredPermission) => {
    // Check if the user has the required permission
    return state.UsersPermission.includes(requiredPermission);
  },
  hasProjectPermission: (state) => (requiredPermission) => {
    // Check if the user has the required permission
    return state.UserProjectPermissions.includes(requiredPermission);
  },

  getUserId(state) {
    return state.user_id;
  },
  isSuperAdmin(state) {
    return state.role === 'Super Admin' || localStorage.getItem('userRole') === 'Super Admin';
  },

  hasRoleInProject: (state) => (projectId, roleName) => {
    const project = state.UserProjectPermissions.find((p) => p.project_id === parseInt(projectId));
    return !!project && project.permissions.includes(roleName);
  }
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate');
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, loginForm) {
    return submitLogin(loginForm).then((response) => {
      if (response.status && response.status == 'success') {
        commit('SET_CURRENT_USER', response.user);
        commit('SET_CURRENT_USER_PERMISSION', response.user.permissions);
        commit('SET_CURRENT_USER_PROJECT_PERMISSION', response.user.project_permissions);
        commit('SET_CURRENT_USER_ID', response.user.id);
        commit('SET_USER_TOKEN', response.access_token);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('userid', response.user.id);
        // Store role in localStorage
        localStorage.setItem('userRole', response.user.role);
        localStorage.setItem('permissions', JSON.stringify(response.user.permissions));
        localStorage.setItem('project_permissions', JSON.stringify(response.user.project_permissions));
        return true;
      } else {
        return false;
      }
    });
  },

  // forgot password
  async forget_password({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/auth/reset/password/sendtoken', data);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
      } else {
        AlertService.errorMessage(response.data.message);
      }
    } catch (error) {
      AlertService.errorMessage(error);
    }
  },

  //   reset password
  async reset_password({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/auth/reset/password/change', data);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        return true; // Indicate success
      } else {
        AlertService.errorMessage(response.data.message);
        return false; // Indicate failure
      }
    } catch (error) {
      AlertService.errorMessage(error.response.data.message);
      return false; // Indicate failure
    }
  },

  // get users data
  getUsersData({ commit, dispatch, getters }) {
    return getUserData().then((resp) => {
      const response = resp.data.data;
      commit('SET_CURRENT_USER', response.user);
      commit('SET_CURRENT_USER_PERMISSION', response.user.permissions);
      commit('SET_CURRENT_USER_PROJECT_PERMISSION', response.user.project_permissions);
      commit('SET_CURRENT_USER_ID', response.user.id);

      // commit('SET_USER_TOKEN',response.access_token)
      localStorage.setItem('permissions', JSON.stringify(response.user.permissions));
      localStorage.setItem('project_permissions', JSON.stringify(response.user.project_permissions));
    });
  },

  async getUserProjectPermissions({ commit, dispatch, getters }) {
    const response = await ApiService.get('/project_user/user-project-permissions');

    if (response.status === 200) {
      const data = response.data.data;
      commit('SET_CURRENT_USER_PROJECT_PERMISSION', data);
    }
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      // getFirebaseBackend()
      //   .logout()
      //   .then((response) => {
      //     resolve(true);
      //   })
      //   .catch((error) => {
      //     reject(this._handleError(error));
      //   });
    });
  },

  // register the user
  register({ commit, dispatch, getters }, { username, email, password } = {}) {
    // if (getters.loggedIn) return dispatch('validate');
    //
    // return getFirebaseBackend()
    //   .registerUser(username, email, password)
    //   .then((response) => {
    //     const user = response;
    //     commit('SET_CURRENT_USER', user);
    //     return user;
    //   });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    // return getFirebaseBackend()
    //   .forgetPassword(email)
    //   .then((response) => {
    //     const message = response.data;
    //     return message;
    //   });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    // const user = getFirebaseBackend().getAuthenticatedUser();
    commit('SET_CURRENT_USER', user);
    return user;
  }
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state));
}
