import useTaskStatus from '../../composable/taskStatus';
import AlertService from '../../services/errors.service';
import ApiService from '../../services/api.service';

const { getTaskStatus, showSingleTaskDetail, addComment } = useTaskStatus();

export const state = {
  task_detail: [],
  task_comments: [],
  task_attachment: [],
  task_activities: [],
  tasks: [],
  sub_tasks: [],
  edit_sub_tasks: [],
  task_time_entries: [],
  oldTaskStatuses: [],
  project: [],

  listview_task: []
};

export const mutations = {
  SET_TASKS(state, newValue) {
    state.tasks = newValue;
    state.oldTaskStatuses = newValue;
  },

  SET_LIST_VIEW_TASK(state, newValue) {
    state.listview_task = newValue;
  },

  updateItems(state, newItems) {
    state.tasks = newItems;
  },

  SET_TASK_DETAIL(state, newValue) {
    state.task_detail = newValue;
  },
  SET_TASK_COMMENTS(state, newValue) {
    state.task_comments = newValue;
  },
  SET_TASK_ATTACHMENT(state, newValue) {
    state.task_attachment = newValue;
  },
  SET_TASK_ACTIVITIES(state, newValue) {
    state.task_activities = newValue;
  },
  SET_TASK_REPORTER(state, newValue) {
    state.task_reporter = newValue;
  },
  SET_SUB_TASK(state, newValue) {
    state.sub_tasks = newValue;
  },

  SET_TIME_ENTRIES(state, newValue) {
    state.task_time_entries = newValue;
  },

  ADD_COMMENTS(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.task_comments.unshift(newData);
  },
  ADD_ATTACHMENT(state, data) {
    // const newData=JSON.parse(JSON.stringify(data))
    state.task_attachment.unshift(data);
  },
  UPDATE_ATTACHMENT(state, { id, data }) {
    const index = state.task_attachment.findIndex((item) => item.id === id);
    const newData = JSON.parse(JSON.stringify(data));
    state.task_attachment[index] = newData;
  },
  DELETE_ATTACHMENT(state, id) {
    const index = state.task_attachment.findIndex((item) => item.id === id);
    state.task_attachment.splice(index, 1);
  },
  ADD_SUB_TASK(state, data) {
    state.sub_tasks.unshift(data);
  },
  UPDATE_SUBTASK_COMPLETE(state, itemId) {
    const item = state.sub_tasks.find((item) => item.id === itemId);
    if (item.completed == 1) {
      item.completed = 0;
    } else {
      item.completed = 1;
    }
  },

  EDIT_SUB_TASK_DATA(state, itemId) {
    const item = state.sub_tasks.find((item) => item.id === itemId);
    state.edit_sub_tasks = [];
    state.edit_sub_tasks.push(item);
  },

  EDIT_SUB_TASK_REQUEST(state, { id, data }) {
    const index = state.sub_tasks.findIndex((item) => item.id === id);
    const newData = JSON.parse(JSON.stringify(data));
    state.sub_tasks[index] = newData;
  }
};

export const getters = {
  getTasks(state) {
    return state.tasks;
  },
  getTaskDetail(state) {
    return state.task_detail;
  },
  getTaskComments(state) {
    return state.task_comments;
  },
  getTaskAttachment(state) {
    return state.task_attachment;
  },
  getTaskActivities(state) {
    return state.task_activities;
  },
  getTaskReporter(state) {
    return state.task_reporter;
  },
  getSubTask(state) {
    return state.sub_tasks;
  },

  getTaskTimeEntries(state) {
    return state.task_time_entries;
  },

  getEditSubTask(state) {
    return state.edit_sub_tasks;
  },
  getListViewTask(state) {
    return state.listview_task;
  }
};

export const actions = {
  async getTasksData({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get('/project/' + id + '/task_tatus');
      const data = response.data.data;
      commit('SET_TASKS', data);
    } catch (error) {}
  },

  // download task attachment tasks/attachments/id
  async downloadAttachment({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get('/tasks/attachments/' + id);
      if (response.status === 200) {
        const data = response.data.data;
        return data;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  },

  async getListViewTasksData({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get('/project/' + id + '/tasks');
      const data = response.data.data;
      commit('SET_LIST_VIEW_TASK', data);
    } catch (error) {}
  },

  async deleteTask({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.delete('/task/' + payload.id);
      AlertService.errorMessage(response.data.message);
      this.getTasksData(payload.task_id);
    } catch (error) {}
  },

  updateItemsAction({ commit }, newItems) {
    commit('updateItems', newItems);
  },

  async addSingleTask({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/task', data);
      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  },

  async addSubTask({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/sub_task', data);
      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = JSON.parse(JSON.stringify(response.data.data));
        commit('ADD_SUB_TASK', data);
      }
    } catch (error) {}
  },

  async editSubTaskRequest({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('/sub_task/' + payload.subtask_id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.data;
        const id = payload.subtask_id;
        commit('EDIT_SUB_TASK_REQUEST', {
          id,
          data
        });
      }
    } catch (error) {}
  },

  async subTaskComplete({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get('/sub_task/' + id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('UPDATE_SUBTASK_COMPLETE', id);
      }
    } catch (error) {}
  },

  task_detail({ commit, dispatch, getters }, id) {
    return showSingleTaskDetail(id).then((response) => {
      if (response.status == 200) {
        const data = JSON.parse(JSON.stringify(response.data.data));
        commit('SET_TASK_DETAIL', data);

        const comments = JSON.parse(JSON.stringify(response.data.data.comments));
        const attachment = JSON.parse(JSON.stringify(response.data.data.attachments));
        const activity = JSON.parse(JSON.stringify(response.data.data.activities));
        const reporter = JSON.parse(JSON.stringify(response.data.data.reporter));
        const time_entries = JSON.parse(JSON.stringify(response.data.data.time_entries));
        const subtasks = JSON.parse(JSON.stringify(response.data.data.subtasks));

        commit('SET_TASK_COMMENTS', comments);
        commit('SET_TASK_ATTACHMENT', attachment);
        commit('SET_TASK_ACTIVITIES', activity);
        commit('SET_TASK_REPORTER', reporter);
        commit('SET_SUB_TASK', subtasks);
        commit('SET_TIME_ENTRIES', time_entries);

        return true;
      } else {
        return false;
      }
    });
  },

  add_comment({ commit, dispatch, getters }, data) {
    return addComment(data).then((response) => {
      AlertService.successMessage(response.data.message);
      const newdata = JSON.parse(JSON.stringify(response.data.data));
      commit('ADD_COMMENTS', newdata);
      dispatch('task_detail', data.task_id);
      return true;
    });
  },

  editSubTask({ commit, dispatch, getters }, id) {
    commit('EDIT_SUB_TASK_DATA', id);
  },

  // attachment

  async add_attachment({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/tasks/attachments', data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = JSON.parse(JSON.stringify(response.data.data));
        commit('ADD_ATTACHMENT', data);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  },
  async delete_Subtask({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.delete('/sub_task/' + id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        dispatch('task_detail', data.task_id);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  },

  async update_attachment({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.post('/tasks/attachments/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.data;
        const id = payload.id;
        commit('UPDATE_ATTACHMENT', {
          id,
          data
        });
      }
    } catch (error) {
      console.log('Error: ', error);
      AlertService.errorMessage('file upload must be required to updated');
    }
  },

  async deleteAttachment({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.delete('tasks/attachments/' + id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('DELETE_ATTACHMENT', id);
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async add_users_groups({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.patch('/task/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        // const data=JSON.parse(JSON.stringify(response.data.data))
        var id = payload.id;
        dispatch('task_detail', id);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }
};
