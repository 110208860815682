import { reactive } from 'vue';

import ApiService from '../services/api.service';

import { useRouter } from 'vue-router';
import getAbility from '../services/ability';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export default function useAuth() {
  const router = useRouter();
  const { can, rules } = new AbilityBuilder(createMongoAbility);
  const formLogin = reactive({
    email: '',
    password: '',
    remember: false
  });

  const submitLogin = async (loginForm) => {
    return await ApiService.post('/auth/login', loginForm)
      .then(async (response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle errors here
        return {
          status: false
        };
        console.log('error' + error);
      });
  };

  const LogoutUser = async () => {
    await ApiService.post('/auth/logout').then((response) => {
      if (response.status == 200) {
        localStorage.clear();
        getAbility.update([]);
        router.push('/');
      }
    });
  };

  return {
    formLogin,
    submitLogin,
    LogoutUser
  };
}
