import { ref } from 'vue';
import ApiService from '@/services/api.service';
import AlertService from '@/services/errors.service';

const isOpenPlanPriceDialog = ref(false);
const plans = ref([]);
const tenantInfo = ref();
let currentPlan = ref();
let allFeatures = ref([]);
let countSummary = ref();
let hasPendingRequest = ref();

export default function usePlanPrice() {
  const setPlanPriceDialog = async (newVal) => {
    isOpenPlanPriceDialog.value = newVal;
  };
  const fetchPlans = async () => {
    return new Promise((resolve, reject) => {
      ApiService.get('/api-plan') // Example API endpoint
        .then((response) => {
          plans.value = response.data; // Set the plans ref to the response
          currentPlan.value = response?.data?.plans?.filter((obj) => obj?.current_plan)[0] ?? null;
          allFeatures.value = response?.data?.features ?? [];
          countSummary.value = response?.data?.count_summary ?? null;
          hasPendingRequest.value = response?.data?.has_pending_request ?? false;

          resolve(response.data); // Resolve the promise with the response data
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
    });
  };
  const subscribePlan = async (payload) => {
    return new Promise((resolve, reject) => {
      ApiService.post('/subscription/subscription-request', payload) // Example API endpoint
        .then((response) => {
          resolve(response.data); // Resolve the promise with the response data
        })
        .catch((error) => {
          AlertService.errorMessage(error.response.data.message);
          reject(error); // Reject the promise if there's an error
        });
    });
  };
  return {
    subscribePlan,
    setPlanPriceDialog,
    tenantInfo,
    currentPlan,
    allFeatures,
    isOpenPlanPriceDialog,
    fetchPlans,
    countSummary,
    hasPendingRequest,
    plans
  };
}
