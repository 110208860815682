import { ref } from 'vue';
import ApiService from '../services/api.service';
import AlertService from '../services/errors.service';

export default function useClients() {
  const Clients = ref({});
  const Client = ref([]);
  const newClient = ref({});
  const editedClient = ref({});
  const selectClient = ref([]);
  const deletedClientId = ref(null);
  const responseResult = ref(false);

  const getClients = async (query) => {
    return await ApiService.get('/client/list?' + new URLSearchParams(query));
    // Clients.value = response.data;
  };

  const getClient = async (id) => {
    let response = await ApiService.get('/client/single/' + id);
    Client.value = response.data.data;
  };

  const selectClients = async () => {
    let response = await ApiService.get('/client/select');
    selectClient.value = response.data.data;
  };

  const storeClient = async (data) => {
    try {
      const response = await ApiService.post('/client', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        newClient.value = response.data.data;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const updateClient = async (id, data) => {
    try {
      const response = await ApiService.post('client/update/' + id, data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const destroyClient = async (id) => {
    try {
      const response = await ApiService.delete('/client/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        deletedClientId.value = response.data.id;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  return {
    Client,
    Clients,
    newClient,
    editedClient,
    deletedClientId,
    selectClient,
    responseResult,
    selectClients,
    getClient,
    getClients,
    storeClient,
    updateClient,
    destroyClient
  };
}
