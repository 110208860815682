import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

export const state = {
  cars: [],
  types: [],
  typesMobile: []
};

export const mutations = {
  SET_CARS(state, newValue) {
    state.cars = newValue;
  },

  SET_TYPES(state, newValue) {
    state.types = newValue;
  },

  SET_TYPES_MOBILE(state, newValue) {
    state.typesMobile = newValue;
  },

  ADD_CARS(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.cars.unshift(newData);
  },
  DELETE_CARS(state, { id, data }) {
    const index = state.cars.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.cars[index] = newData;
  },
  UPDATE_CARS(state, { id, data }) {
    const index = state.cars.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.cars[index] = newData;
  }
};

export const getters = {
  getCars(state) {
    return state.cars;
  },
  getTypes(state) {
    return state.types;
  },

  getTypesMobile(state) {
    return state.typesMobile;
  }
};

export const actions = {
  // cars
  async getCars({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/car?search');

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_CARS', data);
      }
    } catch (error) {}
  },

  async getTypes({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/car/types');

      if (response.status === 200) {
        const data = response.data.carTypesWeb;
        const dataMobile = response.data.carTypesMobile;
        commit('SET_TYPES', data);
        commit('SET_TYPES_MOBILE', dataMobile);
      }
    } catch (error) {}
  },

  async addCars({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/car', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.car;
        commit('ADD_CARS', data);
      }
    } catch (error) {}
  },

  async deleteCars({ commit }, id) {
    try {
      const response = await ApiService.delete('/car/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.car;
        commit('DELETE_CARS', {
          id,
          data
        });
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updateCars({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('car/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.car;
        commit('UPDATE_CARS', {
          id,
          data
        });

        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  }
};
