import useAuth from '../../composable/auth.js';
import useUsers from '../../composable/users.js';
import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

const { submitLogin } = useAuth();
const { getUsers } = useUsers();

export const state = {
  Users: [],
  User: [],
  all_user: [],
  all_user_filter_project: [],
  userid: 0
};

export const mutations = {
  SET_USERS(state, newValue) {
    state.Users = newValue;
  },
  FILTER_USER(state, newValue) {
    state.Users = state.User;
  },
  ADDUSER(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.Users.data.unshift(newData);
  },
  DELETE_USER(state, id) {
    const index = state.Users.data.findIndex((item) => item.id === id);
    state.Users.data.splice(index, 1);
  },
  UPDATE_USER(state, { id, data }) {
    const index = state.Users.data.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.Users.data[index] = newData;
  },
  All_USERS(state, newValue) {
    state.all_user = newValue;
  },
  All_USERS_FILTER_BY_PROJECT(state, newValue) {
    state.all_user_filter_project = newValue;
  },

  GET_USER_ID(state, name) {
    const item = state.all_user.find((item) => item.fullname === name);
    state.userid = item.id;
  }
};

export const getters = {
  getUsers(state) {
    return state.Users;
  },
  getAllUser(state) {
    return state.all_user;
  },
  getAllUserFilterByProject(state) {
    return state.all_user_filter_project;
  }
};

export const actions = {
  getEmployee({ commit, dispatch, getters }, query) {
    return getUsers(query).then((resp) => {
      const response = resp.data;
      commit('SET_USERS', response);
    });
  },

  async getAllUser({ commit, dispatch, getters }) {
    const response = await ApiService.get('/task/assgine-to-users');

    if (response.status === 200) {
      const data = response.data.data;
      commit('All_USERS', data);
    }
  },
  async getAllUserFilterByProject({ commit, dispatch, getters }, project_id) {
    const response = await ApiService.get('/task/assgine-to-users/' + project_id);

    if (response.status === 200) {
      const data = response.data.data;
      commit('All_USERS_FILTER_BY_PROJECT', data);
    }
  },

  async addUser({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/user', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.data;
        commit('ADDUSER', data);
      }
    } catch (error) {}
  },

  async deleteUser({ commit }, id) {
    try {
      const response = await ApiService.delete('/user/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('DELETE_USER', id);
      }
    } catch (error) {
      AlertService.errorMessage(error);
    }
  },

  async UpdateUser({ commit, dispatch, getters }, payload) {
    try {
      if (payload.data.parent_id == null) {
        delete payload.data.parent_id;
      }

      const response = await ApiService.post('user/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.data;
        commit('UPDATE_USER', {
          id,
          data
        });
      }
    } catch (error) {
      // responseResult.value = false;
      console.log(error);
    }
  },

  async searchAndFilterUsers({ commit, state }, searchQuery) {
    const normalizedQuery = searchQuery.toLowerCase().trim();
    if (!normalizedQuery) {
      // If the query is empty, set filtered users to all users
      commit('SET_USERS', state.Users);
    } else {
      const filteredUsers = state.Users.map((user) => user.fullname.toLowerCase().includes(normalizedQuery));
      commit('SET_USERS', filteredUsers);
    }
  },

  get_user_id({ commit, dispatch, getters }, name) {
    commit('GET_USER_ID', name);
  }
};
