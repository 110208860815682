import { AbilityBuilder, createMongoAbility } from '@casl/ability';

const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

const permissions = JSON.parse(localStorage.getItem('permissions'));

const role = JSON.parse(localStorage.getItem('role'));

if (role == 'Super Admin') {
  can('manage', 'all');
}

can(permissions, 'all');

export default build();
