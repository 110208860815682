import { ref } from 'vue';
import ApiService from '../services/api.service';
import AlertService from '../services/errors.service';
import { useRouter } from 'vue-router';
export default function useDepartments() {
  const Departments = ref({});
  const Department = ref([]);
  const selectDepartment = ref([]);
  const newDepartment = ref({});
  const editedDepartment = ref({});
  const deletedDepartmentId = ref(null);
  const responseResult = ref(false);

  const getDepartments = async (query) => {
    return await ApiService.get('/department/list?' + new URLSearchParams(query));
    // Departments.value = response.data;
  };

  const getDepartment = async (id) => {
    let response = await ApiService.get('/department/single/' + id);
    Department.value = response.data.data;
  };

  const selectDepartments = async () => {
    let response = await ApiService.get('/department/select');
    selectDepartment.value = response.data.data;
  };

  const storeDepartment = async (data) => {
    try {
      const response = await ApiService.post('/department', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        newDepartment.value = response.data.data;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const updateDepartment = async (id, data) => {
    try {
      const response = await ApiService.put('department/update/' + id, data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        editedDepartment.value = response.data.data;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const destroyDepartment = async (id) => {
    try {
      const response = await ApiService.delete('/department/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        deletedDepartmentId.value = response.data.id;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  return {
    Department,
    Departments,
    selectDepartment,
    newDepartment,
    editedDepartment,
    responseResult,
    deletedDepartmentId,
    getDepartment,
    getDepartments,
    selectDepartments,
    storeDepartment,
    updateDepartment,
    destroyDepartment
  };
}
