<template>
  <div>
    <el-dialog v-model="isOpenPlanPriceDialog" :close-on-click-modal="true" :show-close="false" :width="getWidth" class="bg-light my-2">
      <template #header>
        <div v-if="canSeeSuccessModel" class="text-center">
          <h2 class="my-4 gradient-text"><strong>Plans & Pricing </strong></h2>

          <lord-icon
            colors="primary:#f06548,secondary:#f79d65"
            delay="2000"
            src="https://cdn.lordicon.com/rvmukzut.json"
            style="width: 100px; height: 100px"
            trigger="loop"
          />

          <h6 class="text-muted my-2">Your upgrade request and pricing details have been submitted. Please wait for approval.</h6>
        </div>
        <div v-else-if="hasPendingRequest" class="text-center">
          <h2 class="my-4 gradient-text"><strong>Plans & Pricing </strong></h2>

          <lord-icon
            colors="primary:#F6A261 ,secondary:#F6A261 "
            delay="2000"
            src="https://cdn.lordicon.com/lltgvngb.json"
            style="width: 100px; height: 100px"
            trigger="loop"
          />

          <h6 class="text-muted my-2">Your upgrade request is currently pending. Please wait for the admin’s approval.</h6>
        </div>
        <div v-else class="text-center">
          <h2 class="my-4 gradient-text"><strong>Plans & Pricing </strong></h2>
          <p class="text-muted">Simple pricing. No hidden fees. Advanced features for your business.</p>
          <p v-if="hasPermissions('subscription-create-request')" class="text-muted">Choose the plan that's right for you!</p>
          <div v-else class="gradient-text">
            <i class="ri-spam-line me-1" style="vertical-align: bottom"> </i>Please contact the admin to upgrade your plan.
          </div>
          <div class="d-inline-flex">
            <ul id="pills-tab" class="nav nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" role="tablist">
              <li class="nav-item" role="presentation">
                <BButton
                  id="month-tab"
                  aria-selected="true"
                  class="nav-link fw-semibold active"
                  data-bs-target="#month"
                  data-bs-toggle="pill"
                  role="tab"
                  type="button"
                  variant="link"
                  @click="handleChangePlanType('monthly')"
                >
                  Monthly
                </BButton>
              </li>
              <li class="nav-item" role="presentation">
                <BButton
                  id="annual-tab"
                  aria-selected="false"
                  class="nav-link fw-semibold"
                  data-bs-target="#annual"
                  data-bs-toggle="pill"
                  role="tab"
                  type="button"
                  variant="link"
                  @click="handleChangePlanType('annual')"
                >
                  Annually
                </BButton>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-if="!hasPendingRequest && !canSeeSuccessModel" #default>
        <!-- Loading State -->
        <div v-if="isLoading" class="d-flex justify-content-center align-items-center" style="min-height: 350px">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="d-block mx-auto" label="large Spinning" type="grow" variant="info" />
          </div>
        </div>
        <!-- No Plans State -->
        <div v-else-if="plans?.plans?.length < 1" class="d-flex justify-content-center align-items-center" style="min-height: 350px">
          <div class="text-center">
            <h5 class="mt-2">Sorry! No Plans Found</h5>
          </div>
        </div>
        <!-- Normal View -->
        <BRow v-else class="justify-content-center">
          <BCol xl="9">
            <BRow class="justify-content-center">
              <BCol v-for="plan in plans.plans" :key="plan.id" lg="4" sm="12">
                <BCard class="pricing-card" no-body>
                  <BCardBody class="p-2 m-3">
                    <div class="text-center">
                      <h3 class="mb-2 gradient-text">
                        <strong> {{ plan?.name ?? '' }}</strong>
                      </h3>
                      <p class="text-muted mb-0" style="min-height: 40px">{{ plan?.description ?? '' }}</p>
                    </div>
                    <div class="pt-4 text-center mb-5 border-bottom">
                      <h2 class="gradient-text">
                        ${{ getPrice(plan) }}
                        <span class="fs-13 text-muted text-capitalize"> / {{ requestedPlanType }}</span>
                      </h2>
                    </div>
                    <div>
                      <ul class="list-unstyled text-muted vstack gap-3">
                        <li v-for="mainFeature in features(plan?.user_limit, plan?.project_limit)" :key="mainFeature.id">
                          <div class="d-flex">
                            <i class="ri-check-line fs-20 align-middle me-3"></i>
                            <p>
                              <strong>{{ mainFeature.name }} : </strong> {{ mainFeature.description }}
                            </p>
                          </div>
                        </li>
                        <li v-for="feature in plans?.features" :key="feature.id">
                          <div class="d-flex">
                            <i
                              :class="havePlan(feature.code, plan.features) ? 'ri-check-line' : 'ri-close-fill '"
                              class="fs-20 align-middle me-3"
                            ></i>
                            <p>
                              <strong>{{ feature.name }} : </strong> {{ feature.description }}
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div class="mt-4">
                        <b-button
                          v-if="hasPermissions('subscription-create-request')"
                          v-loading.fullscreen.lock="fullscreenLoading"
                          :disabled="plan.current_plan || plan.code === 'free'"
                          class="btn w-100 text-white border-0"
                          color="danger"
                          style="background: linear-gradient(135deg, #f06548, #f79d65, #f0c14b)"
                          @click="handleChoosePlan(plan)"
                        >
                          {{ plan.code === 'free' ? 'Get Started' : plan.current_plan ? 'Current Plan' : 'Choose' }}
                        </b-button>
                      </div>
                    </div>
                  </BCardBody>
                </BCard>
              </BCol>
            </BRow>
          </BCol>
        </BRow>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import usePlanPrice from '@/composable/plan-price';
import { ElLoading } from 'element-plus';
import generateDynamicId from '@/composable/createDynamicId';
import { hasPermissions } from '@/helpers/hasPermissions';

const { isOpenPlanPriceDialog, plans, subscribePlan, hasPendingRequest, setPlanPriceDialog, fetchPlans } = usePlanPrice();
const isLoading = ref(false);
const canSeeSuccessModel = ref(false);
const fullscreenLoading = ref(false);
const requestedPlanType = ref('monthly');

const handleChangePlanType = (type) => {
  requestedPlanType.value = type;
};

const havePlan = (codeToCheck, arrayOfObjects) => {
  if (!arrayOfObjects || arrayOfObjects.length === 0) return false;
  return arrayOfObjects.some((obj) => obj?.code === codeToCheck);
};

const getPrice = computed(() => (plan) => {
  return requestedPlanType.value === 'monthly' ? plan?.monthly_price : plan?.annual_price;
});

const features = computed(() => (userLimits, projectLimits) => [
  {
    id: generateDynamicId(),
    code: 'user_limit',
    name: 'User Limit',
    description: `Create up to ${userLimits ?? 'unlimited'} employees for collaboration.`
  },
  {
    id: generateDynamicId,
    code: 'project_limit',
    name: 'Project Limit',
    description: `Initiate up to ${projectLimits ?? 'unlimited'} projects.`
  }
]);

const handleChoosePlan = async (plan) => {
  const payload = {
    plan_id: plan.id,
    type: requestedPlanType.value
  };
  const loadingInstance = ElLoading.service({
    lock: true,
    text: 'Submitting...',
    background: 'rgba(0, 0, 0, 0.7)',
    customClass: 'custom-blur-dark custom-gradient-spinner'
  });

  await subscribePlan(payload)
    .then(async () => {
      await fetchPlans();
      canSeeSuccessModel.value = true;
    })
    .finally(() => {
      loadingInstance.close();
    });
};

const getWidth = computed(() => {
  return hasPendingRequest.value || canSeeSuccessModel.value ? '350px' : '90%';
});
</script>
<style>
.pricing-card:hover {
  border: 1px solid transparent;
  border-image: linear-gradient(135deg, #f06548, #f79d65, #f0c14b) 1;

  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    /* Soft shadow for light mode */ 0 2px 4px rgba(0, 0, 0, 0.06),
    /* Additional shadow for depth */ 0 8px 16px rgba(0, 0, 0, 0.07); /* Larger shadow for hover or dark mode */

  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.gradient-text {
  background: linear-gradient(135deg, #f06548, #f79d65, #f0c14b);
  -webkit-background-clip: text;
  color: transparent;
}

.custom-blur-dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Reduced blur */
  z-index: -1; /* Ensure it's behind the spinner and text */
}

/* Gradient for the loading spinner */
.custom-gradient-spinner .el-loading-spinner::after {
  background: linear-gradient(135deg, #f06548, #f79d65, #f0c14b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  height: 100%; /* Ensure the spinner takes up space */
  width: 100%;
}

/* Gradient for the loading text */
.custom-gradient-spinner .el-loading-text {
  background: linear-gradient(135deg, #f06548, #f79d65, #f0c14b); /* Gradient colors */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Makes the gradient visible */
}
</style>
