import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

export const state = {
  building: [],
  building_with_id: [],
  floor: [],
  room: [],
  User: [],
  building_withfloor: [],
  selectedRoom: null
};

export const mutations = {
  SET_BUILDING(state, newValue) {
    state.building = newValue;
  },

  SET_BUILDING_WITH_ID(state, newValue) {
    state.building_with_id = newValue;
  },

  SET_BUILDING_WITH_FLOOR(state, newValue) {
    state.building_withfloor = newValue;
  },
  ADD_BUILDING(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.building.unshift(newData);
  },
  DELETE_BUILDING(state, { id, data }) {
    const index = state.building.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.building[index] = newData;
  },
  UPDATE_BUILDING(state, { id, data }) {
    const index = state.building.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.building[index] = newData;
  },

  // FLOOR

  SET_FLOOR(state, newValue) {
    state.floor = newValue;
  },
  ADD_FLOOR(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.floor.unshift(newData);
  },
  DELETE_FLOOR(state, { id, data }) {
    const index = state.floor.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.floor[index] = newData;
  },
  UPDATE_FLOOR(state, { id, data }) {
    const index = state.floor.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.floor[index] = newData;
  },

  // ROOM

  SET_ROOM(state, newValue) {
    state.room = newValue;
  },
  ADD_ROOM(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.room.unshift(newData);
  },
  DELETE_ROOM(state, { id, data }) {
    const index = state.room.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.room[index] = newData;
  },
  UPDATE_ROOM(state, { id, data }) {
    const index = state.room.findIndex((item) => item.id === id);

    const newData = JSON.parse(JSON.stringify(data));
    state.room[index] = newData;
  },

  setSelectedRoom(state, room) {
    state.selectedRoom = room;
  }
};

export const getters = {
  getbuilding(state) {
    return state.building;
  },

  getbuilding_with_id(state) {
    return state.building_with_id;
  },

  getfloor(state) {
    return state.floor;
  },
  getroom(state) {
    return state.room;
  },
  getbuilding_withfloor(state) {
    return state.building_withfloor;
  }
};

export const actions = {
  // building
  async get_building({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/building?search');

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_BUILDING', data);
      }
    } catch (error) {}
  },

  async get_building_with_id({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get('/building/' + id);

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_BUILDING_WITH_ID', data);
      }
    } catch (error) {}
  },

  async get_building_withfloor({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/building?search&&with_floors=1');

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_BUILDING_WITH_FLOOR', data);
      }
    } catch (error) {}
  },

  async addBuilding({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/building', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.building;
        commit('ADD_BUILDING', data);
      }
    } catch (error) {}
  },

  async Room({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/building', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.building;
        commit('ADD_ROOM', data);
      }
    } catch (error) {}
  },

  async deleteBuilding({ commit }, id) {
    try {
      const response = await ApiService.delete('/building/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.building;
        commit('DELETE_BUILDING', {
          id,
          data
        });
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updateBuilding({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('building/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.building;
        commit('UPDATE_BUILDING', {
          id,
          data
        });

        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  // floor
  async get_floor({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/floor?search&with_trashed=2323231');

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_FLOOR', data);
      }
    } catch (error) {}
  },
  async get_floor_by_building_id({ commit, dispatch, getters }, query) {
    try {
      const response = await ApiService.get('/floor?' + new URLSearchParams(query));

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_FLOOR', data);
      }
    } catch (error) {}
  },

  async addFloor({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/floor', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.floor;
        commit('ADD_FLOOR', data);
      }
    } catch (error) {}
  },

  async deleteFloor({ commit }, id) {
    try {
      const response = await ApiService.delete('/floor/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.floor;
        commit('DELETE_FLOOR', {
          id,
          data
        });
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updateFloor({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('floor/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.floor;
        commit('UPDATE_FLOOR', {
          id,
          data
        });
        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  // room

  async get_room({ commit, dispatch, getters }) {
    try {
      const response = await ApiService.get('/room?search&&with_building=1&with_floor=1');

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_ROOM', data);
      }
    } catch (error) {}
  },

  async get_room_by_building_id({ commit, dispatch, getters }, query) {
    try {
      const response = await ApiService.get('/floor?' + new URLSearchParams(query));

      if (response.status === 200) {
        const data = response.data.data;
        commit('SET_ROOM', data);
      }
    } catch (error) {}
  },

  async addRoom({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post('/room', data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.room;
        commit('ADD_ROOM', data);
      }
    } catch (error) {}
  },

  async deleteRoom({ commit }, id) {
    try {
      const response = await ApiService.delete('/room/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.room;
        commit('DELETE_ROOM', {
          id,
          data
        });
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updateRoom({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put('room/' + payload.id, payload.data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.room;
        commit('UPDATE_ROOM', {
          id,
          data
        });
        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  }
};
